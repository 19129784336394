import { ChangeEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput = ({
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  return (
    <div className="justify-center mt-1">
      <input
        type={type}
        value={value}
        onChange={onChange}
        className=" w-full py-2 pl-2 justify-center text-neutral-400 placeholder-neutral-400 bg-white rounded-lg border border-solid border-stone-300 font-medium"
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
