import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUserByAPI } from "../../components/api/authentication";
import { CHANNEL } from "../../components/constants/constant";
import { DataUser, ValuePropsGetUser } from "../../utils/interface.global";

export const loginUser = createAsyncThunk(
  "user/login",
  async (
    { name, phone, email }: ValuePropsGetUser,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const props = {
        name,
        phone,
        email,
        kenh: CHANNEL,
      };
      if (name === "" || phone === "" || email === "") {
        throw rejectWithValue("Vui lòng nhập đầy đủ thông tin");
      }
      const response = await loginUserByAPI(props);
      if (response.status) {
        return fulfillWithValue(response.data);
      } else {
        throw rejectWithValue(response.message);
      }
    } catch (error: any) {
      let message = "";
      if (error.message !== undefined) {
        message = error.message;
      } else {
        message = error.payload;
      }
      console.error("error", error.payload, error.message);
      throw rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: false,
    userData: null,
    error: "",
    error_code: "",
  },
  reducers: {
    updateData: (state: any, action: any) => {
      state.userData = action.payload;
      state.status = action.payload !== null;
    },
    logout: (state: any) => {
      state.userData = null;
      state.status = false;
    },
  },
  extraReducers(builder) {
    builder
      // register user
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = true;
        state.userData = action.payload;
        state.error = "";
        state.error_code = "";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = false;
        state.userData = null;
        state.error = action.payload as string;
        state.error_code = new Date().getTime().toString();
        console.error("rejected", state.error);
      });
  },
});

//Reducers
export const { logout, updateData } = userSlice.actions;

// actions
//export const getUserData = (state) => state.user?.userData;
export const getUserData = (state: { user: { userData: DataUser } }) =>
  state.user?.userData;
export const getUserStatus = (state: { user: { status: string } }) =>
  state.user?.status;
export const getUserErr = (state: { user: { error: string } }) =>
  state.user?.error;
export const getUserErrCode = (state: { user: { error_code: string } }) =>
  state.user?.error_code;

// reducer
export default userSlice.reducer;
