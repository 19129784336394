import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bg_phao_hoa from "../assets/bg_phao_hoa.png";
import logo from "../assets/logo.svg";
import wheelImageCafe from "../assets/luckydraw_cafe.png";
import wheelImageOfficeHN from "../assets/luckydraw_office_hn.png";
import wheelImageOfficeHCM from "../assets/luckydraw_office_hcm.png";
import spinnerImage from "../assets/spinner-image.png";
import { getLuckydrawByAPI } from "../components/api/authentication";
import { CHANNEL } from "../components/constants/constant";
import { getUserData, logout, updateData } from "../redux/slices/userSlices";
import { showToast } from "../utils/showToast";
import LuckyWheel from "./LuckyWheel";

function Luckydraw() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const isSpinning = useRef(false);

  const handleSpinStart = async (): Promise<number> => {
    return new Promise(async (resolve, reject) => {
      isSpinning.current = true;
      try {
        const randomId = await getLuckydraw();
        resolve(randomId);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          showToast({ type: "error", message: error.message });
        } else {
          showToast({ type: "error", message: "Có lỗi xảy ra" });
        }
        isSpinning.current = false;
        reject(error);
      }
    });
  };

  const getLuckydraw = async () => {
    const result = await getLuckydrawByAPI({
      id: dataUser.id,
      token: dataUser.token,
      kenh: CHANNEL,
    });
    if (result?.status === true) {
      dispatch(updateData(result.data));
      if (result.data.qua_id > 0) {
        return result.data.qua_id;
      }
    } else {
      showToast({ type: "error", message: result.message });
      if (result.error_code === -5) {
        dispatch(logout());
      } else {
        setTimeout(() => {
          navigate("/");
        }, 6000);
      }
    }
    return 0;
  };

  const handleSpinEnd = (resultId: number) => {
    // console.log(`Kết quả: ${resultId}`);
    isSpinning.current = false;
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    if (isSpinning.current) {
      return;
    }
    if (dataUser.qua_id > 0) {
      navigate("/game");
    }
  }, [dataUser, navigate]);

  const getWheelImage = () => {
    let wheelImage;
    switch (CHANNEL) {
      // @ts-ignore
      case 2:
        wheelImage = wheelImageCafe;
        break;
      // @ts-ignore
      case 3:
        wheelImage = wheelImageOfficeHN;
        break;
      // @ts-ignore
      case 4:
        wheelImage = wheelImageOfficeHCM;
        break;
    }
    return wheelImage;
  };

  return (
    <>
      <img
        src={bg_phao_hoa}
        className={`z-0 absolute inset-0 w-full h-full object-cover transition-transform duration-1000 scale-0`}
        alt="phao_hoa"
      />
      <div className="relative flex flex-col px-5 py-16 mx-auto w-full max-w-[480px] z-10">
        <img
          loading="lazy"
          src={logo}
          className="w-full aspect-[5]"
          alt="logo"
        />
        <div className="flex relative flex-col justify-center mt-16 w-full aspect-square">
          <LuckyWheel
            onSpinStart={handleSpinStart}
            onSpinEnd={handleSpinEnd}
            wheelImage={getWheelImage()}
            spinnerImage={spinnerImage}
          />
        </div>
      </div>
    </>
  );
}

export default Luckydraw;
