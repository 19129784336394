import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import { getUserData } from "../redux/slices/userSlices";
import { URL_API } from "../components/constants/api";
import { showToast } from "../utils/showToast";

function checkBrowserSupport() {
  return (
    "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices
  );
}

function getFacingMode(): string {
  const facingMode = localStorage.getItem("facingMode");
  if (!facingMode) {
    localStorage.setItem("facingMode", "user");
    return "user";
  }
  return facingMode;
}

function setFacingMode(mode: string): void {
  localStorage.setItem("facingMode", mode);
}

export function TakePhoto() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);

  const [hasCameraPermission, setHasCameraPermission] = useState(true);
  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const screenshotImage = useRef<HTMLImageElement>(null);
  const stream = useRef<MediaStream | null>(null);
  const blob = useRef<Blob | null>(null);

  const isBrowserSupported = checkBrowserSupport();
  if (!isBrowserSupported) {
    showToast({
      type: "error",
      message: "Không thể chụp hình trên trình duyệt này",
    });
  }

  const onSubmit = () => {
    if (!blob.current) {
      showToast({
        type: "error",
        message: `Chưa có hình ảnh. Vui lòng nhấn nút "Chụp."`,
      });
      return;
    }
    const formData = new FormData();
    formData.append("id", dataUser.id.toString());
    formData.append("token", dataUser.token);
    formData.append("file", blob.current);
    fetch(URL_API.UPLOAD_PHOTO, { method: "post", body: formData })
      .then(async (result) => {
        const json = await result.json();
        if (!json.status) {
          console.error(json);
          showToast({ type: "error", message: json.message });
          return;
        }
        navigate("/game/logout");
      })
      .catch((error) => {
        console.error(error);
        showToast({ type: "error", message: error.message });
      });
  };

  const onCancel = () => {
    navigate("/game/logout");
  };

  const canTakePhoto = () => {
    return isBrowserSupported && hasCameraPermission;
  };

  const onChangeCamera = () => {
    if (!video.current || !screenshotImage.current) {
      return;
    }
    stream.current?.getTracks().forEach((track) => track.stop());
    const facingMode = getFacingMode();
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
    video.current.hidden = false;
    screenshotImage.current.hidden = true;
    getMedia();
  };

  const onRetry = () => {
    if (!video.current || !canvas.current || !screenshotImage.current) {
      return;
    }
    video.current.hidden = false;
    screenshotImage.current.hidden = true;
  };

  const onTakePhoto = () => {
    if (!video.current || !canvas.current || !screenshotImage.current) {
      return;
    }
    canvas.current.getContext("2d")?.drawImage(video.current, 0, 0);
    canvas.current.toBlob(
      (blobData) => {
        if (
          !video.current ||
          !canvas.current ||
          !screenshotImage.current ||
          !blobData
        ) {
          return;
        }
        blob.current = blobData;
        screenshotImage.current.src = canvas.current.toDataURL("image/jpeg");
        video.current.hidden = true;
        screenshotImage.current.hidden = false;
      },
      "image/jpeg",
      80
    );
  };

  const getMedia = async () => {
    if (!video.current || !canvas.current || !screenshotImage.current) {
      return;
    }
    try {
      const constraints: any = {
        video: {
          width: {
            min: 720,
            max: 1280,
          },
          height: {
            min: 720,
            max: 1280,
          },
          facingMode: getFacingMode(),
        },
        audio: false,
      };
      stream.current = await navigator.mediaDevices.getUserMedia(constraints);
      video.current.onloadedmetadata = () => {
        if (!video.current || !canvas.current) {
          return;
        }
        canvas.current.width = video.current.videoWidth;
        canvas.current.height = video.current.videoHeight;
      };
      video.current.srcObject = stream.current;
    } catch (err) {
      console.error(err);
      if (!(err instanceof Error)) {
        return;
      }
      if (
        err.message === "Permission denied" ||
        err.message === "Permission dismissed"
      ) {
        showToast({
          type: "error",
          message: "Vui lòng cấp quyền sử dụng camera",
        });
        setHasCameraPermission(false);
        return;
      }
      showToast({ type: "error", message: `Có lỗi xảy ra: ${err.message}` });
    }
  };

  const onVisibilitychange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      stream.current?.getTracks().forEach((track) => track.stop());
    } else {
      getMedia();
    }
  }, []);

  useEffect(() => {
    getMedia();
    document.addEventListener("visibilitychange", onVisibilitychange);
    return () => {
      stream.current?.getTracks().forEach((track) => track.stop());
      document.removeEventListener("visibilitychange", onVisibilitychange);
    };
  }, [onVisibilitychange]);

  const cancel = (
    <button
      onClick={onCancel}
      className="font-bold justify-center items-center px-4 py-2 mt-8 w-full text-base text-white bg-yellow-400 rounded-[10000px]"
    >
      Bỏ qua
    </button>
  );
  const content = canTakePhoto() ? (
    <>
      <div className="mb-5">
        <div className="w-full">
          <button
            type="button"
            onClick={onChangeCamera}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Đổi camera
          </button>
          <button
            type="button"
            onClick={onRetry}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Thử lại
          </button>
          <button
            type="button"
            onClick={onTakePhoto}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Chụp
          </button>
        </div>
      </div>
      <div style={{ minHeight: "250px" }}>
        <video ref={video} autoPlay></video>
        <canvas ref={canvas} hidden></canvas>
        <img ref={screenshotImage} alt="" />
      </div>
      {cancel}
      <button
        onClick={onSubmit}
        className="font-bold justify-center items-center px-4 py-2 mt-8 w-full text-base text-white bg-green-400 rounded-[10000px]"
      >
        Hoàn tất
      </button>
    </>
  ) : (
    cancel
  );

  return (
    <div className="flex flex-col px-5 py-16 mx-auto w-full font-bold text-center max-w-[480px]">
      <img
        loading="lazy"
        src={logo}
        className="w-full aspect-[5] mb-10"
        alt="logo"
      />
      {content}
    </div>
  );
}
