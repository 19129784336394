import { createContext } from "react";

const DataContext = createContext<{
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  dataServer: any;
  setDataServer: React.Dispatch<React.SetStateAction<any>>;
} | null>(null);

export default DataContext;
