// export enum URL_API {
//   LOGIN = "http://admin.galaxyaicafe.local:8888/api/user_register",
//   GET_USER = "http://admin.galaxyaicafe.local:8888/api/get_data_user",
//   LUCKYDRAW = "http://admin.galaxyaicafe.local:8888/api/luckydraw",
//   UPLOAD_PHOTO = "http://admin.galaxyaicafe.local:8888/api/upload_photo",
// }
export enum URL_API {
  LOGIN = "https://admin.galaxyaicafe.com/api/user_register",
  GET_USER = "https://admin.galaxyaicafe.com/api/get_data_user",
  LUCKYDRAW = "https://admin.galaxyaicafe.com/api/luckydraw",
  UPLOAD_PHOTO = "https://admin.galaxyaicafe.com/api/upload_photo",
}
