type Props = {
  label: string;
  handleClick: () => void;
  isLoading?: boolean;
};
const Button = ({ label, handleClick, isLoading = false }: Props) => {
  return (
    <>
      {isLoading ? (
        <div className="font-bold text-center items-center px-4 py-2 mt-4 text-center text-white bg-green-400 rounded-[10000px]">
          Loading...
        </div>
      ) : (
        <div
          onClick={handleClick}
          className="font-bold text-center items-center px-4 py-2 mt-4 text-cexnter text-white bg-green-400 rounded-[10000px] hover:bg-slate-300 cursor-pointer"
        >
          {label}
        </div>
      )}
    </>
  );
};

export default Button;
